<template>
  <!--
    The view for the CameraManagement-component
  -->
  <CameraTester
    :installation-id="installationId"
    :lane-number="laneNumber"
  />
</template>

<script>
export default {
  name: "CameraManagementView",
  components: {
    CameraTester: () => import('@/components/CameraManagement/CameraTester.vue'),
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  metaInfo () {
    return {
      title: this.$t('cameraManagement')
    }
  }
}
</script>
